
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Spinner, Input, Button } from 'reactstrap'


const UploadFile = (props) => {

    const isMobile = useMediaQuery({ query: `(max-width: 469px)` });

    const handleFileUploadClick = event => {
        document.getElementById('file-input').click();
    };
    return (
        <>
            <div className="heading-1">Verify ZADA Credentials</div>
            <div className="heading-2">Verify the credentials you have of anyone from any organisation part of the <strong>ZADA Network</strong>. All in one place.</div>

            <div className='align-row-center'>
                <Button className="upload" size={isMobile ? 'sm' : 'lg'} disabled={props.loading} onClick={handleFileUploadClick}
                ><span>{props.loading ? <Spinner animation="grow" size="sm" /> : "Upload File"}</span></Button>
                <Button className="upload" size={isMobile ? 'sm' : 'lg'} disabled={props.loading}
                    onClick={props.requestWebCam} ><span>{props.loading ? <Spinner animation="grow" size="sm" /> : "Scan QR Code"}</span></Button>
            </div>
            <Input id="file-input" type="file" disabled={props.loading}
                onClick={(event) => {
                    event.target.value = null
                }}
                onChange={props.captureFile} className="upload-hidden" />
        </>
    )
}

export default UploadFile;