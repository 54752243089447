import React, { useState } from 'react'
import { Container } from 'reactstrap'
import VerificationServices from 'services/Verification';
import { VerifcationMessage } from 'components';
import { toast, ToastContainer } from 'react-toastify'
import jsQR from "jsqr";
import Jimp from 'jimp';
import ScanQR from './ScanQR';
import UploadFile from './UploadFile';
import './Verification.css';

var PDFJS = require('pdfjs-dist/webpack');

const Flow = () => {

    const [loading, setLoading] = useState(false);
    const [verificationMessageModal, setVerificationMessageModal] = useState(false);
    const [verificationMessageType, setVerificationMessageType] = useState('');
    const [verificationMessageData, setVerificationMessageData] = useState('');
    const [openQRScanner, setOpenQRScanner] = useState(false);
    const [scannerLoading, setScannerLoading] = useState(false);

    let isQrFound = false;

    //Function to do the verification
    const verifyTheQR = async (receivedData) => {
        let qrData = {};
        isQrFound = true;
        setScannerLoading(true);

        try {
            qrData = JSON.parse(receivedData)
        } catch (err) {
            console.log(err);
            toast.error('QR Not Found or Not Scannable!');
            setLoading(false);
            setScannerLoading(false);
            return false;
        }
        let check = await VerificationServices.sendColdVerificationRequest(qrData);

        if (check && check.verified) {
            setVerificationMessageModal(true)
            setVerificationMessageType('success')

            if (qrData.version && qrData.version == 2) {
                setVerificationMessageData(check.data)
            } else if (qrData.v && qrData.v == 3) {
                setVerificationMessageData(check.data)
            } else {
                setVerificationMessageData(qrData.data)
            }

            setLoading(false);
            setScannerLoading(false);
            return true;

        }

        setVerificationMessageModal(true);
        setVerificationMessageType('failed');
        setVerificationMessageData('')
        setLoading(false);
        setScannerLoading(false);
        return false;
    }


    const captureFile = (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            for (let index = 0; index < e.target.files.length; index++) {
                let fileType = e.target.files[index].type;
                const file = e.target.files[index];
                const reader = new FileReader();
                reader.onloadend = async event => {
                    try {
                        // VALIDATION to check file format
                        if (fileType !== 'application/pdf') {
                            // toast.error('Wrong File Format, Currently only PDF is supported');
                            // setLoading(false);
                            // return false
                            try {
                                Jimp.read(event.target.result)
                                    .then(image => {
                                        const code = jsQR(image.bitmap.data, image.bitmap.width, image.bitmap.height);
                                        if (code) {
                                            verifyTheQR(code.data)
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    });
                            } catch (e) {
                                console.log('Error', e)
                            }
                        } else {
                            //In case of PDF
                            const pdf = await PDFJS.getDocument(event.target.result).promise;
                            const pageNum = 1;
                            const page = await pdf.getPage(pageNum);

                            const operatorList = await page.getOperatorList();

                            const validObjectTypes = [
                                PDFJS.OPS.paintImageXObject, // 85
                                PDFJS.OPS.paintImageXObjectRepeat, // 88
                                PDFJS.OPS.paintJpegXObject //82
                            ];

                            const totalNoOfImages = getNoOfImages(validObjectTypes, operatorList.fnArray);
                            let imageCount = 0;

                            operatorList.fnArray.forEach((element, idx) => {

                                if (validObjectTypes.includes(element)) {
                                    const imageName = operatorList.argsArray[idx][0];
                                    page.objs.get(imageName, async (image) => {
                                        imageCount++;

                                        // Uint8ClampedArray
                                        const imageUnit8Array = image.data;
                                        const imageWidth = image.width;
                                        const imageHeight = image.height;

                                        // imageUnit8Array contains only RGB need add alphaChanel
                                        const imageUint8ArrayWithAlphaChanel = addAlphaChannelToUnit8ClampedArray(imageUnit8Array, imageWidth, imageHeight);
                                        const decodedData = jsQR(imageUint8ArrayWithAlphaChanel, imageWidth, imageHeight);

                                        if (decodedData) {
                                            verifyTheQR(decodedData.data)
                                        }

                                        if (!decodedData && !isQrFound && totalNoOfImages === imageCount) {
                                            toast.error('QR Not Found! or Not Scannable!');
                                            setLoading(false);
                                            return false;
                                        }

                                    })
                                }
                            });
                        }
                    } catch (err) {
                        console.log(err);
                        toast.error('QR Not Found or Not Scannable!');
                        setLoading(false);
                        return false;
                    }
                };

                reader.readAsDataURL(file);
            }
        } catch (error) {

            console.log(error);
            toast.error('Something Weng Wrong Please Try Again!');
            setLoading(false);

        }

    };

    function getNoOfImages(validObjectTypes, elements) {
        let count = 0;
        elements.forEach((element, idx) => {
            if (validObjectTypes.includes(element)) {
                count++;
            }
        })
        return count;
    }

    function addAlphaChannelToUnit8ClampedArray(unit8Array, imageWidth, imageHeight) {
        const newImageData = new Uint8ClampedArray(imageWidth * imageHeight * 4);

        for (let j = 0, k = 0, jj = imageWidth * imageHeight * 4; j < jj;) {
            newImageData[j++] = unit8Array[k++];
            newImageData[j++] = unit8Array[k++];
            newImageData[j++] = unit8Array[k++];
            newImageData[j++] = 255;
        }
        return newImageData;
    }


    const requestWebCam = async (e) => {
        e.preventDefault();

        let allMediaDevices = navigator.mediaDevices;

        if (!allMediaDevices || !allMediaDevices.getUserMedia) {
            alert(`Camera not found!`);
            return;
        }

        allMediaDevices.getUserMedia({
            "video": { facingMode: { ideal: 'environment' } }
        })
            .then(function (vidStream) {
                // permission granted:
                setOpenQRScanner(true);
            })
            .catch(function (e) {
                console.log(e.name + ": " + e.message);
            });
    }

    return (
        <>
            <ToastContainer />

            <VerifcationMessage
                setVerificationMessageModal={setVerificationMessageModal}
                type={verificationMessageType}
                verificationMessageModal={verificationMessageModal}
                verificationMessageData={verificationMessageData}
                setOpenQRScanner={setOpenQRScanner}
            />

            <Container className='bg-dar' fluid >
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <div className="page-center">
                            {!openQRScanner ? <UploadFile loading={loading} setLoading={setLoading} captureFile={captureFile} requestWebCam={requestWebCam} />
                                : <ScanQR verifyTheQR={verifyTheQR} openQRScanner={openQRScanner} setOpenQRScanner={setOpenQRScanner} scannerLoading={scannerLoading} setScannerLoading={setScannerLoading} />}
                            <p className="contact-message">Do you face any issues? Email us at <a href="mailto: verify@zada.io">verify@zada.io</a></p>
                        </div>
                    </div>
                </div>
            </Container>

        </>
    )
}

export default Flow;
