import Auth from 'helpers/Auth';
import axios from 'axios'

class VerificationServicesClass {

    sendColdVerificationRequest = (qrData) => {
        return new Promise((resolve, reject) => {
            try {
                axios.post(`${process.env.REACT_APP_API_URL}/api/credential/verify`,
                    {
                        recData: qrData,
                        // signature: qrData.signature,
                        // tenantId: qrData.tenantId,
                        // keyVersion: qrData.keyVersion
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + Auth.getToken()
                        },
                    }).then((res) => {
                        resolve(res.data.result)
                    }).catch((e) => {
                        resolve(false)
                        console.log({ e })
                    })

            } catch (error) {
                resolve(false)
            }
        })
    }

}

const VerificationServices = new VerificationServicesClass;

export default VerificationServices;
