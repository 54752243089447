import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom'
import AdminLayout from "./layouts/Main.js";

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/*" render={(props) => <AdminLayout {...props} />} />
                </Switch>
            </Router>
        </div>
    )
}

export default App
