
import React from 'react';
import { QrReader } from 'react-qr-reader';
import { MdKeyboardBackspace } from "react-icons/md";

const ScanQR = (props) => {

    const scanOverlay = () => {
        return (
            <svg viewBox="0 0 100 100" className="scanOverlay">
                <path fill="none" d="M13,0 L0,0 L0,13" stroke="#001871" stroke-width="5"></path>
                <path fill="none" d="M0,87 0,100 L13,100" stroke="#001871" stroke-width="5"></path>
                <path fill="none" d="M87,100 L100,100 L100,87" stroke="#001871" stroke-width="5"></path>
                <path fill="none" d="M100,13 L100,0 87,0" stroke="#001871" stroke-width="5"></path>
            </svg>
        )
    }

    const loaderOverlay = () => {
        return (
            <div className="loader"></div>
        )
    }

    return (
        <div>
            <div className="back-button" onClick={(e) => props.setOpenQRScanner(false)}>
                <MdKeyboardBackspace size="2rem" />
                Go back
            </div>
            <div className="align-row-center">
                <QrReader
                    constraints={{ facingMode: { ideal: 'environment' } }}
                    className="videoCam"
                    ViewFinder={props.scannerLoading ? loaderOverlay : scanOverlay}
                    videoStyle={{ width: "100%", height: "inherit" }}
                    scanDelay="1000"
                    onResult={(result, error) => {
                        if (!!result && !props.scannerLoading) {
                            props.verifyTheQR(result.text)
                        }
                        if (!!error) {
                            // console.error("error invalid qr")
                        }
                    }}
                />
            </div>
        </div >
    )
}

export default ScanQR;