import { Verification } from "./views";

var routes = [
  {
    path: "/",
    name: "Home",
    icon: "ni ni-bullet-list-67 text-red",
    component: Verification,
    layout: "/",
    roles: []
  },
];

export default routes;
