
/*eslint-disable*/

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {

  const config = {
    footer: {
      "link": "https://zada.io",
      "text": " ZADA Solutions Pte Ltd, All rights reserved."
    },
    adminFooterLinks: [
      {
        "title": "About Us",
        "link": "https://zada.io/about-us/"
      },
      {
        "title": "Our Manifesto",
        "link": "https://zada.io/our-manifesto/"
      },
      {
        "title": "Privacy Policy",
        "link": "https://zada.io/privacy-policy/"
      }
    ]
  };

  return (
    <div className="footer-container-wrapper fixed-bottom">
      <div className="footer-container">
        <div className="custom-footer-bg">
          <div className="page-center">
            <Container fluid>
              <Row className="align-items-center justify-content-xl-between">
                <Col xl="6">
                  <div className="copyright text-center text-xl-left text-muted">
                    <span style={{ color: 'white' }}> © {new Date().getFullYear()}{" "} </span>
                    <a
                      className="ml-1"
                      href={config.footer?.link}
                      rel="noopener noreferrer"
                      style={{ color: 'white' }}
                      target="_blank"
                    >
                      {config.footer?.text}
                    </a>
                  </div>
                </Col>

                <Col xl="6">
                  <Nav className="nav-footer justify-content-center justify-content-xl-end">
                    {config.adminFooterLinks?.map((data) => {
                      return (
                        <NavItem key={data.link}>
                          <NavLink
                            href={data.link}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ color: 'white' }}
                          >
                            {data.title}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
