const { Modal, ModalHeader, ModalBody, Button } = require("reactstrap")


const VerificationSuccess = (props) => {

    let dialogData = {};
    if (props.verificationMessageData !== '') {
        dialogData = JSON.parse(Buffer.from(props.verificationMessageData, "base64").toString());
    }

    function closeModal() {
        props.setVerificationMessageModal(false)
        props.setOpenQRScanner(false)
    }

    return (
        <div>
            <div className="centered-content d-flex justify-content-center">
                <img
                    alt="..."
                    height="96px"
                    src={require("../../assets/img/theme/check.png").default}
                />
            </div>
            <div className="centered-content d-flex justify-content-center">
                <h4>Verification Successful</h4>
            </div>
            <br />
            <div className="centered-content d-flex justify-content-center">
                <p>The certificate is valid!</p>
            </div>
            <div className="centered-content d-flex justify-content-center">
                <table id="data-table">
                    <tbody>
                        <tr>
                            <th> Credential Field </th>
                            <th> Value </th>
                        </tr>
                        {Object.keys(dialogData).map((key, i) => (
                            <tr key={i}>
                                <td> {key} </td>
                                <td> {dialogData[key]}</td>
                            </tr>
                        )
                        )}
                    </tbody>
                </table>
            </div>


            <br />
            <Button
                color="success"
                style={{ width: "100%" }}
                onClick={closeModal}
            >
                Continue
            </Button>

        </div>
    );
}

const VerificationFailed = (props) => {

    function closeModal() {
        props.setVerificationMessageModal(false)
        props.setOpenQRScanner(false)
    }

    return (
        <div>
            <div className="centered-content d-flex justify-content-center">
                <img
                    alt="..."
                    height="96px"
                    src={require("../../assets/img/theme/cross.png").default}
                />
            </div>
            <div className="centered-content d-flex justify-content-center">
                <h4>Verification Failed</h4>
            </div>
            <br />
            <div className="centered-content d-flex justify-content-center">
                <p>The certificate is invalid</p>
            </div>

            <br />
            <Button
                style={{
                    width: "100%",
                    backgroundColor: "#c82606",
                    color: "#ffffff",
                }}
                onClick={closeModal}
            >
                Continue
            </Button>
        </div>
    );
}

const VerifcationMessage = (props) => {
    return (
        <Modal
            isOpen={props.verificationMessageModal}
            size="lg"
            centered
        >
            <ModalHeader>
                <span><h5 className="text-xl">Verfication Status</h5></span>
            </ModalHeader>
            <ModalBody>
                {props.type === 'success' && <VerificationSuccess {...props} />}
                {props.type === 'failed' && <VerificationFailed {...props} />}
            </ModalBody>
        </Modal>

    )
}

export default VerifcationMessage;