
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Navbar, Footer } from "components";

import routes from "routes.js";

const Main = (props) => {

  const mainContent = React.useRef(null);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    const currentRoute = routes.filter((data) => data.layout + data.path === path)
    if (currentRoute?.length) {
      return currentRoute[0].name;
    } else {
      return "Brand"
    }
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <Navbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/verification" />
        </Switch>
        <Footer />
      </div>
    </>
  );
};

export default Main;
