import React from "react";
import logo from "../../assets/img/brand/zada-verify.png"

const MainNavbar = (props) => {
  return (
    <>
      <div className="header-container-wrapper">
        <div className="header-container">
          <div className="custom-header-bg">
            <div className="page-center">
              <div className="logo">
                <img alt="logo" height={60} src={logo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNavbar;
