const jwt = require("jsonwebtoken");

const Auth = {
    authenticate(token, exp) {
        localStorage.setItem('itoken', token);
        localStorage.setItem('exp', exp);
    },

    signout() {
        localStorage.removeItem('itoken');
        localStorage.removeItem('exp');
    },

    getAuth() {
        try {
            var exp = String(Math.floor(Date.now() / 1000) + (120))
            var token = jwt.sign({ sub: process.env.REACT_APP_TENANT_ID }, process.env.REACT_APP_TENANT_SECRET, { expiresIn: '120s' });
            this.authenticate(token, exp)
            return token;
        } catch (error) {
            console.log(error.message)
            return undefined;
        }
    },

    getToken() {
        let nowUnixEpoch = Math.round(Date.now() / 1000);
        let expUnixEpoch = Number(localStorage.getItem('exp'));

        if ((expUnixEpoch - nowUnixEpoch) <= 1) {
            return this.getAuth();
        } else {
            return localStorage.getItem('itoken');
        }
    }

};

export default Auth;